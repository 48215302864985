
import * as Components from "./Charm"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "charm"
}

